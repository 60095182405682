<script setup>
import { useForm } from '@inertiajs/vue3';

import { inject } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
// Icons
import saveIcon from '@i/save-white.svg';
import dayjs from 'dayjs';

const route = inject('route');
const props = defineProps({
    location: Object,
});

const form = useForm({
    date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
    place_of_qr_code: {
        nl: '',
        en: '',
    },
});

const sendForm = () => {
    window.open(route('staff.location.download-qr', { location: props.location?.id, ...form.data() }), '_blank');
};
</script>

<template>
    <FormSingleSettingBar :title="$t('QR code')">
        <FormKit type="form" id="download-qr-form" @submit="sendForm" :actions="false">
            <FormKit
                type="date"
                name="valid_until"
                :label="$t('Valid until')"
                v-model="form.date"
                :errors="form.errors.date"
                :value="form.date"
            />

            <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
                <FormKit
                    type="text"
                    name="place_of_qr_code_nl"
                    :label="$t('Place of QR code {locale}', { locale: 'NL' })"
                    v-model="form.place_of_qr_code.nl"
                    :errors="form.errors.place_of_qr_code?.nl"
                    :value="form.place_of_qr_code.nl"
                />
                <FormKit
                    type="text"
                    name="place_of_qr_code_en"
                    :label="$t('Place of QR code {locale}', { locale: 'EN' })"
                    v-model="form.place_of_qr_code.en"
                    :errors="form.errors.place_of_qr_code?.en"
                    :value="form.place_of_qr_code.en"
                />
            </div>
            <div class="flex items-center justify-end w-full">
                <div class="">
                    <Button
                        type="button"
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        :text="$t('Save')"
                        :icon="saveIcon"
                        @click="sendForm"
                    />
                </div>
            </div>
        </FormKit>
    </FormSingleSettingBar>
</template>
